@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.template-select {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 7px 0px;
  
  width: 98%;
  padding: 15px 0px 0px 0px
}
.template-select-title {
  flex: 0 0 125px;
  color: rgb(86, 88, 91);
  font: $font-std;
  padding: 9px 5px 0px 0px;
}
.template-select-list {
  flex: 1 1;
  width: 100%;
  font: $font-std;
}
