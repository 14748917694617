@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.custom-values-container {
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
}
.custom-values-container :global([role=presentation]) {
  width: 100%;
}
.custom-values-title-row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 25px 0px;
    border-bottom: 1px solid rgb(137, 150, 169);
    width: 98%;
    padding: 3px 3px 7px 3px;
}
.custom-values-title {
  font: $font-subtitle-sm;
  flex: 1 1;
}
.custom-values {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  width: 100%;
  font: $font-std;
} 
.custom-value-row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
  width: 100%;
}
.custom-value-row-empty {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
  margin-top: 8px;
  width: 100%;
}
.custom-value-name {
  display: flex;
  flex: 0 0 200px;
}
.custom-value-read-view {
  text-align: left;
  padding: 7px 3px;
  cursor: pointer;
  &:hover {
    background-color: rgba(219, 230, 235, 1);
  }
}
.custom-value-traits-select {
  width: 100%;
  margin: 7px 15px 0px 10px;
}
.custom-value-traits {
  display: flex;
  flex: 1 1;
}
.custom-value-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0 0 70px;
}
.icon-trash {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  cursor: pointer;
}
.new-value-button {
  background-color: rgb(208, 227, 255);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font: $font-std-bold;
  color: rgb(52, 96, 162);
  padding: 10px 22px;
  margin-top: -6px;
  cursor: pointer;
}
.new-value-button:hover {
  background-color: rgb(180, 208, 252);
}