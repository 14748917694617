@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.root {
  align-items: center;
  background-color: rgb(219, 230, 235);
  display: flex;
  flex-direction: column;
  gap: 0px 0px;
  height: 100%;
}

.error-message {
  background-color: red;
  width: 100%;
  height: 30px;
  position: fixed;
  opacity: 0.7;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.main-content-container {
  align-items: flex-start;
  display: flex;
  gap: 0px 12px;
  margin: 0px 0px 30px;
  width: 97.16%;
  @include sm {
    flex-direction: column;
    gap: 8px 12px;
  }
  height: 100%;
}
.left-column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 23px 0px;
  width: 352px;
  height: 100%;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.left-column-title {
  color: rgb(0, 0, 0);
  font: $font-std;
}
.left-column-title_box {
  height: 34px;
  width: 86.36%;
  padding: 20px 0px 0px 10px;
}
.left-column-title_span0 {
  font: $font-std;
}
.left-column-title_span1 {
  font: $font-std-bold;
}
.left-column-content {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.accounts-list-container {
  display: flex;
  flex-direction: column;
  gap: 23px 0px;
  margin: 7px 0px 0px;
}
.accounts-list-title {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 1px 0px;
  padding: 5px 0px 8px 34px;
  border-bottom: 1px solid rgb(208, 227, 255);
}
.medium_title {
  color: rgb(0, 0, 0);
  font: $font-std;
  width: 76.14%;
}
.accounts-list {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 11.93%;
  width: 201px;
}
.accounts-list-item {
  font: $font-std;
  margin-bottom: 20px;
  cursor: pointer;
}

.accounts-list-item:hover,
.accounts-list-item-active:hover {
  color: rgb(52, 96, 162);
}

.accounts-list-item-active {
  color: rgb(52, 96, 162);
  font: $font-std;
  text-decoration: underline;
  margin-bottom: 20px;
  cursor: pointer;
}

.right-column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px 0px;
  width: 100%;
  height: 100%;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.right-column-top-spacer {
  flex: 0 0 46px;
}
.new-account-button {
  background-color: rgb(72, 139, 239);
  border-radius: 12px 12px 12px 12px;
  text-align: center;
  font: 500 16px / 1.2 "Inter", Helvetica, Arial, serif;
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin: -2px 8px 0px auto;
  padding: 6px 22px;
}
.new-account-button:hover {
  background-color: rgb(101, 157, 241);
}

.new-project-button {
  background-color: rgb(208, 227, 255);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font: $font-std-bold;
  color: rgb(52, 96, 162);
  padding: 10px 22px;
  margin-top: -6px;
  cursor: pointer;
}
.new-project-button:hover {
  background-color: rgb(180, 208, 252);
}

.right-column-content-container {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.account-details-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 41px 0px;
  margin: 9px 19px;
}
.account-details-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.account-details-main :global([role="presentation"]) {
  width: 100%;
}

.account-details-title-row {
  display: flex;
  gap: 0px 10px;
  justify-content: space-between;
  @include sm {
    align-items: center;
    flex-direction: column;
    gap: 8px 10px;
  }
}
.account-details-name-container {
  display: flex;
  gap: 0px 15px;
  margin: 0px 0px 15px;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.account-details-actions {
  justify-content: center;
  flex: 0 0 40px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 8px;
  width: 136px;
}
.account-details-actions > button {
  /*height: 20px;
  min-height: 20px;*/
  border-radius: 12px;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 5px;
  outline-style: none;
  width: 136px;
  flex: 0 0 136px;
}
.account-details-actions > button:focus-visible {
  outline: none !important;
}
.account-details-actions > button[aria-expanded="true"] {
  background: rgb(9 30 66 / 20%);
  color: #42526e;
  outline-style: none;
}
.account-details-icon {
  display: flex;
  height: 40px;
  width: 40px;
}
.account-details-title {
  font: $font-hero;
  display: flex;
  margin-top: -11px;
  width: 100%;
}
.account-logo-image {
  height: 75px;
  width: 250px;
  object-fit: contain;
  object-position: center right;

  cursor: pointer;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.account-logo-actions-col {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.account-logo-positioner {
  flex: 0 0 75px;
  position: relative;
  top: 4px;
  left: 0px;
  width: 320px; /* leave enough room for logo/uploader */
}
.uploader-layer {
  & > label {
    height: 110px;
  }
}
.uploader-cancel-container {
  position: absolute;
  width: 100%;
  top: 82px;
  display: flex;
}
.uploader-cancel {
  text-decoration: underline;
  padding: 2px 12px;
  margin: auto;
  cursor: pointer;
  &:hover {
    background-color: #dfdfdf;
  }
}
.account-logo-replace-icon {
  position: absolute;
  right: -5px;
  bottom: -13px;
  width: 24px;
  height: 24px;
  background-color: rgb(208, 227, 255);
  border-radius: 5px 5px 5px 5px;
  padding: 2px 2px;
}
.account-logo-replace-icon:hover {
  background-color: rgb(180, 208, 252);
}

.account-details-attributes-container {
  display: flex;
  flex-direction: column;
  gap: 18px 0px;
}
.account-details-attribute-row {
  align-items: flex-start;
  display: flex;
  gap: 0px 28px;
}
.account-details-attribute-name {
  color: rgb(86, 88, 91);
  font: $font-std;
  flex: 0 0 150px;
}
.account-details-attribute-value {
  color: rgb(0, 0, 0);
  font: $font-std;
  display: flex;
  flex: 1 1;
}
.account-details-attribute-value-spacer {
  flex: 0 0 300px;
}
.account-details-attribute-value-editable {
  color: rgb(0, 0, 0);
  font: $font-std;
  margin-top: -13px;
  display: flex;
  flex: 1 1;

  [role="presentation"] {
    width: 100%;
  }
}

.account-details-projects {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px 0px;
  width: 100%;
}
.account-details-projects-header {
  display: flex;
  align-items: flex-start;
  gap: 0px 10px;
  justify-content: space-between;
  width: 100%;
  @include sm {
    column-gap: 10px;
  }
  padding-bottom: 7px;
  border-bottom: 1px solid rgb(137, 150, 169);
}
.account-projects-title-row {
  display: flex;
  align-items: flex-start;
  gap: 0px 10px;
  justify-content: space-between;
  width: 100%;
  @include sm {
    column-gap: 10px;
  }
}
.account-projects-cell-name {
  display: flex;
  flex: 1 1;
  gap: 0px 14px;
  font: $font-std;
  cursor: pointer;
}

.account-projects-cell-name:hover {
  color: rgb(52, 96, 162);
}

.account-projects-cell-count {
  display: flex;
  justify-content: left;
  flex: 0 0 30%;
  gap: 0px 14px;
  font: $font-std;
  @include md {
    flex-basis: 20%;
  }
}
.account-projects-cell-count-justify {
  width: 32px;
  text-align: center;
}
.account-projects-cell-actions {
  display: flex;
  justify-content: center;
  flex: 0 0 40px;
  padding-top: 0;
  padding-bottom: 0;
}
.account-projects-cell-actions > button {
  height: 20px;
  min-height: 20px;
  outline-style: none;
}
.account-projects-cell-actions > button:focus-visible {
  outline: none !important;
}
.account-projects-cell-actions > button[aria-expanded="true"] {
  background: rgb(9 30 66 / 20%);
  color: #42526e;
  outline-style: none;
}

.account-projects-cell-date {
  display: flex;
  justify-content: center;
  flex: 0 0 143px;
  gap: 0px 14px;
  font: $font-std;
}
.account-projects-header-cell {
  font: $font-std-bold;
}
.projects-icon {
  height: 28px;
  width: 28px;
}
.title-text {
  color: rgb(0, 0, 0);
  font: $font-title;
  margin: 2px 0px 0px;
}
.participants-icon {
  height: 32px;
  width: 32px;
}
.read-view-content {
  padding: 4px 4px;
  /*margin-top: -5px;*/
  &:hover {
    background-color: rgba(219, 230, 235, 1);
  }
}
.non-editable-content {
  padding: 0px 6px;
}
.account-details-projects-rows {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px 0px;
  width: 100%;
}
.accounts-projects-not-found {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 29px 0px;
  width: 95.66%;
  font: $font-std-bold;
}

.account-projects-row {
  align-items: center;
  display: flex;
  gap: 0px 10px;
  justify-content: space-between;
  width: 100%;
}
