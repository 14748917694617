@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.project-slides-container {
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
}
.project-slides-container :global([role="presentation"]) {
  width: 100%;
}
.project-slides-title-row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
  width: 98%;
  padding: 3px 3px 12px 3px;
  align-items: center;
  height: 45px;
}

.project-slide-set-selection {
  flex: 1 1;
  align-items: flex-start;
}

.project-slides-title {
  font: $font-subtitle-sm;
  flex: 0 0 100px;
}
.project-slides {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  width: 100%;
  font: $font-std;
}
.project-slide-row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
  width: 100%;
}
.project-slide-row-empty {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
  margin-top: 8px;
  width: 100%;
}
.project-slide-name {
  display: flex;
  flex: 0 0 200px;
}
.project-slide-read-view {
  text-align: left;
  padding: 7px 3px;
  cursor: pointer;
  &:hover {
    background-color: rgba(219, 230, 235, 1);
  }
}
.project-slide-traits-select {
  width: 100%;
  margin: 7px 15px 0px 10px;
}
.project-slide-traits {
  display: flex;
  flex: 1 1;
}
.project-slide-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0 0 70px;
}
.icon-trash {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  cursor: pointer;
}

.rename-slide-set-button {
  background-color: rgb(237 243 252);
  border-radius: 12px 12px 12px 12px;
  cursor: pointer;
  padding: 7px 8px 6px 8px;
  margin: 6px;
}

.new-slide-button {
  background-color: rgb(208, 227, 255);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font: $font-std-bold;
  color: rgb(52, 96, 162);
  padding: 10px 22px;
  cursor: pointer;
}
.new-slide-button:hover {
  background-color: rgb(180, 208, 252);
}
.new-slide-button input[type="file"] {
  display: none;
}

.new-slide-set-button {
  background-color: rgb(208, 227, 255);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font: $font-std-bold;
  color: rgb(52, 96, 162);
  padding: 10px 22px;
  margin-left: 55px;
  margin-right: 10px;
  cursor: pointer;
}
.new-slide-set-button:hover {
  background-color: rgb(180, 208, 252);
}

.dnd-scroll-container {
  overflow-y: auto;
  overflow-x: clip;
  /*height: calc(100vh - 680px);*/
  min-height: 440px;
  width: 97%;
  display: flex;
  flex-grow: 1;
}
.dnd-container {
  display: flex;
  flex-direction: row;
  touch-action: none;
  margin: 1rem auto;
  width: 100%;
  flex-grow: 1;
  min-height: min-content;
}

.dropzone {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background-color: #f0f0f0;
  min-height: min-content;
}

.dropzone.left {
  flex: 4;
  margin-right: 10px;
}
.dropzone.right {
  flex: 1;
}

.grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.grid-item-content {
  width: 120px;
  height: 90px;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.grid-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.grid-item-label {
  position: absolute;
  text-transform: capitalize;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: x-small;
  cursor: default;
  min-height: 24px;
}

.grid-item-index {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 14px;
  min-width: 14px;
  text-align: center;
  padding: 1px 1px 1px 1px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: x-small;
}
.dropzone.right .grid-item-index {
  display: none;
}

.grid-item-type-container {
  position: absolute;
  text-transform: capitalize;
  top: 0px;
  left: 0px;
  right: 0px;
  cursor: default;
  min-height: 14px;
  display: flex;
  flex-direction: row;
}

.grid-item-type-label {
  flex: 0 1 50px;
  background-color: rgb(255 118 0);
  color: white;
  font-size: x-small;
  border-radius: 6px 6px 6px 6px;
  padding: 2px 2px 2px 2px;
  margin-left: 1px;
  margin-top: 1px;
  text-align: center;
}

.grid-item-type-spacer {
  flex: 1 1;
}

.grid-item-type-action-delete {
  flex: 0 1 20px;
  width: 14px;
  height: 16px;
  background-color: rgb(46, 47, 51);
  border-radius: 6px 6px 6px 6px;
  cursor: pointer;
  padding: 0px 2px 1px 2px;
  margin-right: 1px;
  margin-top: 1px;
}

.dropzone.left .grid-item-type-action-delete {
  display: none;
}

.grid-item-type-action-delete svg {
  height: 14px;
}
