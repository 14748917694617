@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.top-bar {
  background-color: rgb(47, 70, 81);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flex_row {
  align-items: center;
  display: flex;
  gap: 0px 10px;
  justify-content: space-between;
  margin: 14px auto 14px;
  width: 94.05%;
}
.flex_row1 {
  display: flex;
  gap: 0px 10px;
  justify-content: space-between;
  width: 450px;
}
.top-bar-logo {
  height: 45px;
  margin: 0px 0px;
  width: 45px;
}
.top-bar-nav {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font: $font-std-bold;
  padding: 11px 0px 11px;
}
.top-bar-nav-active{
  color: rgb(255, 255, 255);
  cursor: pointer;
  font: $font-std-bold;
  padding: 11px 0px 5px;
  margin-bottom: 6px;
  border-bottom: 2px solid #ffffff;
}
.top-bar-logout-button {
  margin: -6px 0px 0px 0px;
  color: rgb(72, 139, 239);
  width: 125px;
  font: $font-std;
  padding: 12px 32px;
  cursor: pointer;
}
.top-bar-logout-button:hover {
  color: rgb(101, 157, 241);
}

.flex_col {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px 0px;
  margin: 11px 0px 0px;
  width: 131px;
}

.top-bar-profile {
  display: flex;
  gap: 0px 18px;
}
.profile-icon {
  height: 32px;
  width: 32px;
}
.profile-name {
  color: rgb(255, 255, 255);
  font: $font-std-bold;
  margin: 7px 0px 0px;
}