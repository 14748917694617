@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.consultants {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 7px 0px;
  
  width: 98%;
  padding: 15px 0px 0px 0px
}
.consultants-title {
  flex: 0 0 125px;
  color: rgb(86, 88, 91);
  font: $font-std;
  padding: 9px 5px 0px 0px;
}
.consultants-list {
  flex: 1 1;
  width: 100%;
  font: $font-std;
}

/* css for under project settings
.consultants {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px 0px;
  
  width: 98%;
  padding: 20px 3px 7px 3px;
}
.consultants-title {
  font: $font-title;
  flex: 0 0;
  padding-bottom: 13px;
  border-bottom: 1px solid rgb(137, 150, 169);
  width: 100%;
}
.consultants-list {
  flex: 0 0;
  width: 100%;
  font: $font-std;
} */
