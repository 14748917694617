@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.root {
  align-items: center;
  background-color: rgb(219, 230, 235);
  display: flex;
  flex-direction: column;
  gap: 0px 0px;
  height: 100%;
}

.error-message {
  background-color: red;
  width: 100%;
  height: 30px;
  position: fixed;
  opacity: 0.7;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.main-content-container {
  align-items: flex-start;
  display: flex;
  gap: 0px 12px;
  margin: 0px 0px 30px;
  width: 97.16%;
  @include sm {
    flex-direction: column;
    gap: 8px 12px;
  }
  height: 100%;
}
.left-column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px 0px;
  width: 352px;
  height: 100%;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.left-column-title {
  color: rgb(0, 0, 0);
  font: $font-std;
}
.left-column-title_box {
  height: 34px;
  width: 86.36%;
  padding: 20px 0px 0px 10px;
}
.left-column-title_span1 {
  font: $font-title-bold;
}
.account-name-container {
  font: $font-title-bold;
  padding-bottom: 10px;
  padding-top: 7px;
}
.back-to-accounts {
  height: 33px;
  padding-top: 15px;
  cursor: pointer;
  font: $font-std-bold;
}
.right-column-header {
  width: 100%;
  height: 40px;
  font: $font-std-bold;
  padding-top: 3px;
}
.left-column-top-content {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0;
  align-items: center;
  gap: 0px;
  padding: 13px 10px;
}
.left-column-content {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;
}
.projects-list-container {
  display: flex;
  flex-direction: column;
  gap: 23px 0px;
  margin: 7px 0px 0px;
}
.projects-list-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1px 0px;
  padding: 0px 0px 7px 34px;
  border-bottom: 1px solid rgb(208, 227, 255);
}
.medium_title {
  color: rgb(0, 0, 0);
  font: $font-std;
  width: 76.14%;
}
.projects-list {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 11.93%;
  width: 201px;
}
.projects-list-item {
  font: $font-std;
  margin-bottom: 20px;
  cursor: pointer;
}

.projects-list-item:hover,
.projects-list-item-active:hover {
  color: rgb(52, 96, 162);
}

.projects-list-item-active {
  color: rgb(52, 96, 162);
  font: $font-std;
  text-decoration: underline;
  margin-bottom: 20px;
  cursor: pointer;
}

.right-column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px 0px;
  width: 100%;
  height: 100%;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.new-report-button {
  background-color: #0b6cfd;
  border-radius: 12px 12px 12px 12px;
  margin: -4px 0px 0px auto;
  text-align: center;
  font: $font-std-bold;
  color: rgb(255, 255, 255);
  padding: 10px 22px;
  cursor: pointer;
}
.new-report-button:hover {
  background-color: rgb(72, 139, 239);
}
.new-report-button input[type="file"] {
  display: none;
}

.project-participants-cell-picture {
  flex: 0 0 40px;
  /* background-color: rgb(219, 230, 235); */
  padding: 0px 0px;
  position: relative;

  img {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    object-fit: cover;
    object-position: top center;
    border-radius: 50%;
  }
  input[type="file"] {
    display: none;
  }
}

.participant-trash-icon {
  position: absolute;
  display: none;
  top: 0px;
  right: 0px;
  width: 14px;
  height: 14px;
  background-color: #ccc;
  border-radius: 3px 3px 3px 3px;
}
.project-participants-cell-picture:hover .participant-trash-icon {
  display: block;
}

.account-logo-container {
  flex: 1 1;
}

.account-logo-image {
  height: 70px;
  width: 164px;
  object-fit: contain;
  padding: 3px 0px 3px 0px;
}

.total-records-container {
  flex: 0 0 200px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 10px;
}
.total-records-label {
  flex: 1 1;
  text-align: right;
}
.total-records-value {
  flex: 0 0;
  padding-left: 10px;
}
.right-column-content-container {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.project-details-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px 0px;
  margin: 15px 15px;
  padding: 8px 8px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);
}
.project-details-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.project-details-main :global([role="presentation"]) {
  width: 100%;
}
.read-view-content {
  padding: 4px 4px;
  /*margin-top: -5px;*/
  &:hover {
    background-color: rgba(219, 230, 235, 1);
  }
}
.non-editable-content {
  padding: 0px 6px;
}
.project-details-title-row {
  display: flex;
  gap: 0px 10px;
  justify-content: space-between;
  @include sm {
    align-items: center;
    flex-direction: column;
    gap: 8px 10px;
  }
}
.project-details-name-container {
  display: flex;
  gap: 0px 21px;
  margin: 0px 0px 18px;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}

.account-details-icon {
  height: 32px;
  width: 32px;
  margin-bottom: 6px;
  margin-right: 10px;
  vertical-align: middle;
}
.project-details-title {
  font: $font-hero;
  display: flex;
  margin-top: -8px;
  width: 100%;
}
.project-logo-image {
  width: 410px;
  height: 94px;
  object-fit: contain;
  object-position: center right;
  @include sm {
    margin-bottom: unset;
    margin-top: unset;
    width: 100%;
  }
}
.project-details-description-container {
  color: rgb(0, 0, 0);
  font: $font-std;
  margin-top: -7px;
  display: flex;
  flex: 1 1;
  flex-direction: row;

  [role="presentation"] {
    width: 100%;
  }
}
.project-details-consultants-container {
  color: rgb(0, 0, 0);
  font: $font-std;
  margin-top: -7px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
}
.project-details-templates-container {
  color: rgb(0, 0, 0);
  font: $font-std;
  margin-top: -7px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
}

.project-details-description-label {
  display: flex;
  flex: 0 0 121px; /* not 125 to allow for padding */
  color: rgb(86, 88, 91);
  font: $font-std;
  padding: 9px 5px 0px 0px;
}
.project-details-description-edit {
  margin-top: -3px;
  width: 100%;
}
.project-details-attributes-container {
  display: flex;
  flex-direction: column;
  gap: 28px 0px;
}
.project-details-attribute-row {
  align-items: flex-start;
  display: flex;
  gap: 0px 28px;
}
.project-details-attribute-name {
  color: rgb(86, 88, 91);
  font: $font-std;
  flex: 0 0 200px;
}
.project-details-attribute-value {
  color: rgb(0, 0, 0);
  font: $font-std;
}

.project-advanced-settings-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0px 0px;
  width: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.project-advanced-settings-section-title {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 0px 13px;
}

.project-advanced-settings-title {
  display: flex;
  vertical-align: middle;
  font: $font-subtitle;
  cursor: pointer;
}

.expanded-content-container {
  width: 100%;
  margin-left: 15px;
  border-left: 2px solid #f0f0f0;
  padding-left: 15px;
}
.project-participants-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0px 0px;
  width: 100%;
  margin-top: 0px;
}

.project-participants-section-title {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
}

.project-participants-title {
  display: flex;
  vertical-align: middle;
  font: $font-title;
  cursor: pointer;
}

.project-details-participants {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px 0px;
  width: 100%;
}
.project-details-participants-header {
  display: flex;
  align-items: flex-start;
  gap: 0px 10px;
  justify-content: space-between;
  width: 100%;
  @include sm {
    column-gap: 10px;
  }
  padding-bottom: 7px;
  border-bottom: 1px solid rgb(137, 150, 169);
}
.project-participants-title-row {
  display: flex;
  align-items: flex-start;
  gap: 0px 10px;
  justify-content: space-between;
  width: 100%;
  @include sm {
    column-gap: 10px;
  }
}
.project-participants-cell-name {
  display: flex;
  flex: 1 1;
  gap: 0px 14px;
  font: $font-std;
}
.project-participants-cell-count {
  display: flex;
  justify-content: left;
  flex: 0 0 30%;
  gap: 0px 14px;
  font: $font-std;
  @include md {
    flex-basis: 20%;
  }
}
.project-participants-cell-date {
  display: flex;
  justify-content: right;
  flex: 0 0 200px;
  gap: 0px 14px;
  font: $font-std;
}
.project-participants-header-cell {
  font: $font-std-bold;
}
.project-details-icon {
  height: 32px;
  width: 32px;
}
.download-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.download-generated {
  color: green;
}
.download-not-generated {
  color: #000;
}
.refresh-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.trash-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.warning-icon {
  height: 20px;
  width: 20px;
}
.no-icon {
  height: 20px;
  width: 20px;
}
.drop-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-top: -3px;
}
.settings-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-top: -2px;
  margin-left: 3px;
}
.title-text {
  color: rgb(0, 0, 0);
  font: $font-title;
  margin: 4px 0px 0px;
}
.participants-icon {
  height: 32px;
  width: 32px;
}

.project-details-participants-rows {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
  width: 100%;
  flex: 0 0;
}

.project-participants-not-found {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 29px 0px;
  width: 95.66%;
  font: $font-std-bold;
}

.project-participants-row {
  align-items: center;
  display: flex;
  gap: 0px 10px;
  justify-content: space-between;
  width: 100%;
}
