@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.trait-settings-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  margin-top: 20px;
}

.trait-settings-container :global([role=presentation]) {
  width: 100%;
}

.trait-settings-title-row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 25px 0px;
  font: $font-subtitle-sm;
  padding: 3px 3px;
}

.trait-settings-table {
  border-collapse: collapse;
  border-spacing: 0;

  th {
    font: $font-std-bold;
    width: 90px;
    text-align: center;
    border-bottom: 1px solid #333;
  }
  th.first {
    border-bottom: 0px;
  }
  td {
    font: $font-std;
    width: 90px;
    text-align: center;
  }
  td.first {
    text-align: left;
  }
  [data-ds--text-field--input] {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .trait-settings-table-read-view {
    text-align: center;
    padding: 7px 3px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: rgba(219, 230, 235, 1);
    }
  }
}

.trait-label-strength {
  color: #DC6E00;
}
.trait-label-motivation {
  color: #395D7D;
}
.trait-label-challenge {
  color: #C00000;
} 

