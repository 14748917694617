@import url("https://fonts.googleapis.com/css?family=Inter:500,400");
/*@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");*/
      * {
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
      }
      html,
      body {
        margin: 0px;
        height: 100%;
      }
      /* a blue color as a generic focus style */
      button:focus-visible {
        outline: 2px solid #4a90e2 !important;
        outline: -webkit-focus-ring-color auto 5px !important;
      }
      a {
        text-decoration: none;
      }

:root {
  --container-background: rgba(250, 253, 254, 1);
  --page-background: rgba(219, 230, 235, 1); 
  --variable-collection-icon-colors: rgba(62, 70, 74, 1);
  background-color: rgb(219, 230, 235);
}

.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}
