@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.root {
  align-items: center;
  background-color: rgb(219, 230, 235);
  display: flex;
  flex-direction: column;
  gap: 0px 0px;
  height: 100%;
}

.error-message {
    background-color: red;
    width: 100%;
    height: 30px;
    position: fixed;
    opacity: 0.7;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.dashboard-widgets-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
  gap: 12px 0px;
  width: 80%;
}

.dashboard-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  font: $font-title-bold;
  flex: 1 1;
  justify-content: left;
}

.dashboard-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 12px 12px;
}

.dashboard-container-grow {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 250px;
  padding: 15px 30px 15px 15px;
}

.dashboard-container-single {
  background-color: rgb(250, 253, 254);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.247);
  display: flex;
  flex-direction: column;
  flex: 0 0 250px;
  height: 250px;
  padding: 7px 13px;
  justify-content: center;
  gap: 15px 0px;
}
.dashboard-widget-highlighted1 {
  background-color: #EDF9FF
}
.dashboard-widget-highlighted2 {
  background-color: #C7E8F8
}


.dashboard-stat-number {
  font: $font-xxl-bold;
  display: flex;
  justify-content: center;
  width: 100%;
}

.dashboard-stat-data-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px 5px;
  justify-content: center;
}

.account-details-icon {
  height: 32px;
  width: 32px;
}

.project-details-icon {
  height: 32px;
  width: 32px;
}
.download-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.standard-text {
  color: rgb(0, 0, 0);
  font: $font-std;
  margin: 7px 0px 0px;
}
.title-text {
  color: rgb(0, 0, 0);
  font: $font-title;
  margin: 4px 0px 0px;
}
.participants-icon {
  height: 32px;
  width: 32px;
}

.latest-accounts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  gap: 19px 0px;
  margin-top: 27px;
  margin-left: 39px;
  min-width: 250px;
}

.latest-accounts-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px 10px;
  width: 100%;
}
.latest-accounts-name {
  display: flex;
  flex: 1 1;
  color: rgb(0, 0, 0);
  font: $font-std;
  cursor: pointer;
}
.latest-accounts-name:hover, .latest-projects-name:hover{
  color: rgb(52, 96, 162);;
}

.latest-accounts-date {
  display: flex;
  flex: 0 0 110px;
  color: rgb(0, 0, 0);
  font: $font-std;
  justify-content: right;
}

.latest-projects-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  gap: 19px 0px;
  margin-top: 27px;
  margin-left: 39px;
  min-width: 511px;
}
.latest-projects-header-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px 7px;
}
.latest-projects-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px 10px;
  width: 100%;
}
.latest-projects-name {
  display: flex;
  flex: 1 1;
  color: rgb(0, 0, 0);
  font: $font-std;
  cursor: pointer;
}
.latest-projects-date {
  display: flex;
  flex: 0 0 110px;
  color: rgb(0, 0, 0);
  font: $font-std;
  justify-content: right;
}
.latest-projects-participants {
  display: flex;
  flex: 0 0 55px;
  color: rgb(0, 0, 0);
  font: $font-std;
  justify-content: center;
}
.quick-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px 10px;
}














