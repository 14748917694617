@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.delete-modal {
  padding: 0;
  margin: 0;
}

.delete-modal h2 {
  background-color: rgb(47, 70, 81);
  color: #fff;
  height: 50px;
  width: 100%;
  padding-top: 14px;
  padding-left: 13px;
  font-size: 18px;
}

.modal-content {
  padding: 10px;
}
.modal-content p {
  margin-bottom: 10px;
  margin-top: 10px;
}
.modal-content strong {
  font-size: 16px;
  font-weight: bolder;
}
.modal-content-warning {
  background-color: rgb(255, 0, 0, 0.75);
  padding: 12px;
  font-weight: bold;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.cancel-button {
  background-color: rgb(208, 227, 255);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font: $font-std-bold;
  color: rgb(52, 96, 162);
  padding: 10px 22px;
  margin-top: -6px;
  cursor: pointer;
}
.cancel-button:hover {
  background-color: rgb(180, 208, 252);
}
.confirm-button {
  background-color: rgb(255, 0, 0);
  border-radius: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font: $font-std-bold;
  color: rgb(255, 255, 255);
  padding: 10px 22px;
  margin-top: -6px;
  cursor: pointer;
}
.confirm-button:hover {
  background-color: rgb(200, 0, 0);
}
.confirm-button:disabled {
  background-color: #ccc;
}
