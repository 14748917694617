// color

// background color

// font Stack
$font-small: 400 12px/1 "Inter", Helvetica, Arial, serif;
$font-std: 400 16px/1.2 "Inter", Helvetica, Arial, serif;
$font-std-bold: 500 16px/1.2 "Inter", Helvetica, Arial, serif;
$font-subtitle: 400 19px/1.21 "Inter", Helvetica, Arial, serif;
$font-subtitle-sm: 400 18px/1.21 "Inter", Helvetica, Arial, serif;
$font-title: 400 22px/1.21 "Inter", Helvetica, Arial, serif;
$font-title-bold: 500 22px/1.21 "Inter", Helvetica, Arial, serif;
$font-hero: 400 28px/1.21 "Inter", Helvetica, Arial, serif;
$font-xxl-bold: 500 60px/1.21 "Inter", Helvetica, Arial, serif;
$fallback-font-stack: Helvetica, Arial, serif;
