@use "../../styles/utils" as *;
@use "../../styles/global" as *;

.project-settings-container {
  margin-bottom: 10px;
}

.on-target-icon {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}
